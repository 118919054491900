<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">

	  <div class="top bgFFF paddingTB20 paddingLR20">
		  <span class="top_name">字段名：</span>
		  <el-select v-model="dictionaryType" placeholder="请选择" @change="getCarType" style="width: 310px;">
              <el-option label="所属派出所" :value="1"/>
              <el-option label="所属交警分局" :value="2"/>
		  </el-select>
	  </div>

      <info-table
          class="bgFFF"
	      v-if="searchShow"
        :num="1"
        :nameStyle="{ height: '200px', width: '100px' }"
        :valueStyle="{ height: '200px' }"
        :tableData="tableData"
      >
        <template v-slot:obeyType>
          <div class="contentWrapper">
            <div class="itemBox audit">
              <GeminiScrollbar>
                <div
                  class="typeItem"
                  :class="{ activeItem: activeKey == ind }"
                  v-for="(val, ind) in typeList"
                  @click="itemClick(ind, val)"
                  :key="ind"
                >
                  {{ val.dictionaryContent }}
                </div>
              </GeminiScrollbar>
            </div>
            <div class="buttonBox">
              <div class="tipWord">
                <!--当前设置内容为PDA中，稽查取证页面，违规类型的配置选项-->
              </div>
              <div>
                <el-button
                  style="color: green;"
                  type="text"
                  @click="dialogVisible = true"
                >
				  [新增]
				</el-button>
              </div>
              <div>
                <el-button
                  style="color: green;"
                  type="text"
                  @click="deleteItem"
                >
				  [删除]
				</el-button
                >
              </div>
            </div>
          </div>
        </template>
      </info-table>

      <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="30%"
        @closed="handleClose"
      >
        <el-form ref="form" :model="formInline" label-width="120px">
          <el-form-item
            :rules="{ required: true, message: `请输入${dictionaryType === 1 ? '所属派出所' : '所属交警分局'}`}"
            prop="typeVal"
            :label="`${dictionaryType === 1 ? '所属派出所' : '所属交警分局'}`"
          >
            <el-input
              v-model="formInline.typeVal"
              maxlength="50"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-footer" style="margin:0 auto; text-align:center; width:100%">
          <el-button type="primary" @click="insertType">确 定</el-button>
		      <span style="display:inline-block; width:10px;"></span>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import infoTable from "@/components/infoTable/infoTable";
export default {
  name: "auditAndAudit",
  components: {
    infoTable,
  },
  data() {
    return {
	    searchShow: true,
      dictionaryType: 1,
      activeKey: null,
      typeVal: "",
      dialogVisible: false,
      formInline: {
        typeVal: "",
      },
      typeList: [
        {
          inspectionTypeCode: 0,
          dictionaryContent: "",
          parkFilingDictionaryId: 0,
        },
      ],
      tableData: [
        {
          name: "所属派出所",
          key: "obeyType",
          required: true,
        },
      ],
    };
  },
  methods: {
     // 选择车辆类型
    getCarType(val) {
		if (!this.searchShow) {
			this.searchShow = true
		}
		if (this.tableShow) {
			this.tableShow = false
		}
		this.tableData[0].name = this.dictionaryType === 1 ? "所属派出所" : '所属交警分局';
		this.getList();
	},
    handleClose() {
      this.$refs.form && this.$refs.form.resetFields();
    },
    backClick() {
      // this.typeList = [];
    },
    insertType() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$axios
            .post(`/acb/2.0/parkFilingDictionary/add`, {
              data: {
                dictionaryContent: this.formInline.typeVal,
                dictionaryType: this.dictionaryType
			  }
            })
            .then((res) => {
              this.dialogVisible = false;
              this.getList();
            })
            .catch((e) => {
              this.dialogVisible = false;
            });
        } else {
          return false;
        }
      })
    },
    deleteItem() {
      if (this.activeKey === null) {
          this.$alert("未选择类型", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
	  	  return
	  }
      this.$axios.post(`/acb/2.0/parkFilingDictionary/delete/${this.typeList[this.activeKey].parkFilingDictionaryId}`).then((res) => {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
          })
          this.getList();
        });
      this.activeKey = null;
    },
    itemClick(key) {
      this.activeKey = key;
    },
    getList() {
      this.$axios.get(`/acb/2.0/parkFilingDictionary/list`, {
          data: { dictionaryType: this.dictionaryType},
        }).then((res) => {
          this.typeList = res.value.list;
        });
    }
  },
  created() {
    this.getList();
  },
  mounted() {},
  activated() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
>>>.colName
  background #fff
.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    padding: 22px 22px 0 22px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.upload-demo {
  display: inline-block;
}
</style>
<style scoped>
.top {
	width:100%;
	margin:12px auto 18px;
	font-size:16px;
}

.top .top_name {
	margin:0 15px 0 0;
}
.el-button {
  margin: 0 !important;
}
.contentWrapper {
  overflow: hidden;
}
.itemBox {
  width: 300px;
  height: 200px;
  border-right: 1px solid #eee;
  float: left;
  overflow: auto;
}
.buttonBox {
  float: left;
  padding-top: 50px;
  position: relative;
}
.buttonBox > div {
  padding-left: 20px;
}
.typeItem {
  padding: 0 4px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}
.typeItem:hover {
  background: green;
  color: white;
}
.activeItem {
  background: green;
  color: white;
}
.tipWord {
  width: 500px;
  position: absolute;
  left: 100px;
  top: 80px;
  color: #777;
}
</style>
<style>
.audit .gm-scrollbar .thumb {
  background-color: #eee !important;
}
.audit .gm-scrollbar.-vertical {
  margin-right: -3px;
  margin-top: -2px;
  background-color: #fff !important;
}
</style>
